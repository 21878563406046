<template>
  <div id="my_footer">
    <div class="wrapper">
      <ul class="ulList">
        <li>
          <router-link to="/menhuHome">门户平台</router-link>
          <Divider type="vertical" />
        </li>
        <li>
          <router-link to="/kongjianHome">空间平台</router-link>
          <Divider type="vertical" />
        </li>
        <li>
          <router-link to="/ziyuanHome">资源平台</router-link>
          <Divider type="vertical" />
        </li>
        <li class="jichupingtai"
            @click="jichuPingtai"
            style="cursor:pointer;">基础平台
          <Divider type="vertical" />
        </li>
        <li>数据平台
        </li>
      </ul>
    </div>
    <template v-if="dataArr1.length>0">
      <span>{{ dataArr1[0].danwei_name + dataArr1[0].zhuban_danwei }}</span>
      <span>{{ dataArr1[1].address_name + dataArr1[1].zhuban_address }}</span>
      <span>{{ dataArr1[2].youbian_name + dataArr1[2].youbian }}</span>
      <span>
        <a class="jishuzhichi"
           :href="jszcUrl"
           target="_blank">技术支持:教之源
        </a>
      </span>
    </template>
    <div class="footerWrap"
         v-if="dataArr.length>0">
      <div>
        <a :href="dataArr[0].gongwang_url"
           target="_blank">
          <!-- 后台返回拼接字符串老数据 -->
          <!-- {{dataArr[0].gongwang_name + ':' + dataArr[0].gongwang}} -->
          <!-- 新改：后边同这个一样 -->
          {{dataArr[0].gongwang}}
        </a>
        <a :href="dataArr[1].icp_url"
           target="_blank">
          {{dataArr[1].icp}}
        </a>
        <!-- <a :href="dataArr[2].jingying_url"
           target="_blank">
          {{dataArr[2].jingying}}
        </a>
        <a :href="dataArr[3].jiaoyu_url"
           target="_blank">
          {{dataArr[3].jiaoyu}}
        </a> -->
      </div>
      <!-- <div v-for="(item, index) in footerInfomation?.detail[0]?.content"
           :key="index + '1'">
        <a v-if="item.name != ''"
           :href="item.wailianurl"
           target="_blank">{{item.name + ':' + item.value}}</a>
        <span v-else>{{ item.value }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerBar',
  props: {
    footerInfomation: {
      type: Object,
      default: () => { }
    },
    footerInfomation0: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      jszcUrl: '',
      dataArr: this.footerInfomation?.detail[0]?.content,
      dataArr1: this.footerInfomation?.detail[1]?.content,
    }
  },
  methods: {
    jichuPingtai () {
      window.open('login_jcpt?type=' + 4, '_blank')
    }
  },
  mounted () {
    if (window.location.origin == "http://localhost:8080/home") {
      this.jszcUrl = "http://172.27.224.29:8081/"
    } else if (window.location.origin == "https://menhu.jsytest.top" || window.location.origin == "http://menhu.jsytest.top") {
      this.jszcUrl = "https://xxbwww.jsytest.top/"
    } else {
      this.jszcUrl = "https://www.jzyxxb.cn/"
    }
  }
}
</script>

<style scoped lang="less">
.jichupingtai:hover {
  text-decoration: underline;
}
#my_footer {
  background: #f6f6f6;
  // height: 150px;
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding: 5px 0 10px 0;
  // width: 100%;
  // height: 60px;
  // position: fixed;
  // left: 0;
  z-index: 1000;
  // bottom: 0;
  // background: #777;
  font-size: 14px;
  color: #fff;
  span,
  .jishuzhichi {
    padding: 0 10px;
    color: #666;
  }
  .jishuzhichi:hover {
    text-decoration: underline;
  }
  .footerWrap {
    margin-top: 5px;
    // width: 1200px;
    // margin: 10px auto;
    // display: flex;
    // justify-content: center;
    // flex-wrap: wrap;
    // align-items: center;
    // flex-shrink: 0;
    div {
      display: inline;
      a {
        padding: 0 10px;
        color: #666666;
      }
      a:hover {
        color: #666666;
        text-decoration: underline;
      }
    }
  }
}
.wrapper {
  height: 40px;
  .ulList {
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    li {
      color: #666;
      // width: 24%;
      // text-align: left;
      // height: 10px;
      // line-height: 10px;
      margin: 0px 0 10px 0;
      float: left;
      list-style: none;
      // border-right: 1px solid #666;
      // padding: 0 10px;
      a {
        color: #666;
      }
      a:hover {
        color: #666666;
        text-decoration: underline;
      }
    }
  }
}
.wrapper .links::after {
  clear: both;
  display: block;
  content: '';
}
/deep/.ivu-divider,
.ivu-divider-vertical {
  height: 1.4em;
  margin: 0 30px;
  background: #666666;
}
</style>
